.container {
  margin: 0 auto;
  padding: 0 12px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1060px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1420px;
  }
}
