@import url("https://necolas.github.io/normalize.css/latest/normalize.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Raleway:wght@400;500&display=swap");
@import url("https://cdn.jsdelivr.net/npm/@coreui/icons@3.0.1/css/all.min.css");

@import "variables";
@import "container";
@import "header";

@include dark-mode;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  align-items: center;

  font-family: "Open Sans", sans-serif;
  position: relative;
  background: transparent;
  color: var(--font-color);

  &::before {
    content: "";
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100vh;
    z-index: -1;
    background: var(--background-image) center top / cover no-repeat var(--background-color);
  }
}
