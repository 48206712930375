header {
  width: 100%;

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 48px;
    padding: 0;
    margin: 0;

    a,
    a:hover,
    a:focus {
      color: var(--font-color);
      text-decoration: none;
    }
  }

  h2 {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    margin: 20px 0 30px;
    color: rgba(var(--font-color), 0.8);
    font-weight: 500;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        color: var(--font-color);
        font-weight: 500;

        &:first-child {
          span {
            padding-left: 0;
          }
        }

        span {
          padding: 0 10px;
        }

        i {
          color: var(--secondary-color);
          font-size: 20px;
          vertical-align: middle;
        }
      }
    }
  }

  .social-links {
    margin-top: 30px;
    display: flex;

    a {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--font-color);
      line-height: 1;
      margin-right: 12px;
      width: 40px;
      height: 40px;
      background: var(--social-icon-bg);
      border-radius: 50%;
      text-decoration: none;
      transition: all 0.3s ease-in-out 0s;

      i.cib-helm {
        font-size: 20px;
      }

      &:hover,
      &:focus {
        background-color: var(--primary-color);
        color: var(--social-icon-color-hover);
      }
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
  }

  .logo {
    img {
      width: 200px;
    }
  }
}

@media (max-width: 992px) {
  header {
    .container,
    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    .logo {
      img {
        width: 120px;
      }
    }
  }
}
